<template>
<!--  <div class="chat-desktop" v-if="($route.path !== '/chat')&&($route.name !== 'dialog')" :style="modalBoard?{right:'600px'}:{}">-->
  <div class="chat-desktop" v-if="($route.path !== '/chat')&&($route.name !== 'dialog')">
<!--    <div id="seventeen-step" class="img-report-wrapper" v-if="!areOptionsVisible" @click="areOptionsVisible=true">
      <div class="img-report">
        <img src="/my/menu/chat_mob_menu.svg">
      </div>
      <div class="counter-messages" v-if="dialogList.unread">{{ dialogList.unread }}</div>
    </div>-->
<!--    <div class="modal-report-chat" id="eighteenth-step" v-if="areOptionsVisible" :style="modalBoard?{right:'610px'}:{}">-->
    <div class="modal-report-chat" id="eighteenth-step" v-if="areOptionsVisible">
      <div v-if="filterType===3&&!showChat" class="list-desktop-chat">
        <div class="chat-report-title">
          <div>Вопросы</div>
          <div @click="closeModal" class="close-btn">X</div>
        </div>
        <div class="chat-type-container">
          <div class="list-chats">
            <div class="list-chat-el" @click="createChat(3)" v-if="(role!=='admin')&&(role!=='tp')">
              <div class="list-chat-el__icon">
                <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_3598_16720)">
                    <circle cx="36.4749" cy="33.9574" r="33.9574" fill="#D9D9D9"/>
                  </g>
                  <path d="M44.7722 36.2183H37.2261V43.7643C37.2261 44.1646 37.0671 44.5485 36.784 44.8315C36.501 45.1146 36.1171 45.2736 35.7169 45.2736C35.3166 45.2736 34.9327 45.1146 34.6497 44.8315C34.3666 44.5485 34.2076 44.1646 34.2076 43.7643V36.2183H26.6616C26.2613 36.2183 25.8774 36.0593 25.5944 35.7762C25.3114 35.4932 25.1523 35.1093 25.1523 34.709C25.1523 34.3088 25.3114 33.9249 25.5944 33.6419C25.8774 33.3588 26.2613 33.1998 26.6616 33.1998H34.2076V25.6537C34.2076 25.2535 34.3666 24.8696 34.6497 24.5866C34.9327 24.3035 35.3166 24.1445 35.7169 24.1445C36.1171 24.1445 36.501 24.3035 36.784 24.5866C37.0671 24.8696 37.2261 25.2535 37.2261 25.6537V33.1998H44.7722C45.1724 33.1998 45.5563 33.3588 45.8393 33.6419C46.1224 33.9249 46.2814 34.3088 46.2814 34.709C46.2814 35.1093 46.1224 35.4932 45.8393 35.7762C45.5563 36.0593 45.1724 36.2183 44.7722 36.2183Z" fill="var(--white-basic)"/>
                  <defs>
                    <filter id="filter0_d_3598_16720" x="0.435108" y="0" width="72.079" height="72.08" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="2.08247"/>
                      <feGaussianBlur stdDeviation="1.04124"/>
                      <feComposite in2="hardAlpha" operator="out"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3598_16720"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3598_16720" result="shape"/>
                    </filter>
                  </defs>
                </svg>
              </div>
              <div class="list-chat-el__text">
                <div class="list-chat-el__text_title">Новый чат</div>
                <div class="list-chat-el__text_description">Задайте интересующий вас вопрос</div>
              </div>
            </div>
            <div class="list-chat-el" @click="openArchive" style="align-items: center">
              <div class="list-chat-el__icon">
                <svg width="56" height="56" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg"><g id="archive_outline_56__Icons-56/archive_outline_56" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="archive_outline_56__archive_outline_56"><path d="M0 0h56v56H0z"></path><path d="M44.5 18c.83 0 1.5.67 1.5 1.5v21a7.5 7.5 0 0 1-7.5 7.5h-21a7.5 7.5 0 0 1-7.5-7.5v-21c0-.83.67-1.5 1.5-1.5h33ZM43 21H13v19.5a4.5 4.5 0 0 0 4.5 4.5h21a4.5 4.5 0 0 0 4.5-4.5V21Zm-21.5 9h13a1.5 1.5 0 0 0 0-3h-13a1.5 1.5 0 0 0 0 3Zm-9-22h31a6.5 6.5 0 0 1 6.5 6.5v4a2.5 2.5 0 0 1-2.5 2.5h-39A2.5 2.5 0 0 1 6 18.5v-4A6.5 6.5 0 0 1 12.5 8ZM47 18v-3.5a3.5 3.5 0 0 0-3.5-3.5h-31A3.5 3.5 0 0 0 9 14.5V18h38Z" id="archive_outline_56__Icon-Color" fill="currentColor" fill-rule="nonzero"></path></g></g></svg>
              </div>
              <div class="list-chat-el__text">
                <div class="list-chat-el__text_title">Архив диалогов</div>
              </div>
            </div>
            <div class="list-chat-el" v-for="chat in dialogList.dialogs" @click="openChat(chat.dialog_id,dialogList.users[`${chat.dialog_id}`].username,dialogList.users[`${chat.dialog_id}`].user_role, dialogList.users[`${chat.dialog_id}`].subrole)">
              <div class="list-chat-el__icon">
                <img src="/my/noavatar.png" />
              </div>
              <div class="list-chat-el__text">
                <div class="list-chat-el__text_title" v-if="dialogList.users[`${chat.dialog_id}`]">
                  <slot v-if="dialogList.users[`${chat.dialog_id}`].username">{{dialogList.users[`${chat.dialog_id}`].username}}</slot>
                  <slot v-else>{{$t('Неизвестный')}}</slot>
                  <span v-if="dialogList.users[`${chat.dialog_id}`].user_role"><br/>
                    (<slot v-if="dialogList.users[`${chat.dialog_id}`].user_role==='bank'">{{$t('РедакторБанк')}}</slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='tests'">{{$t('РедакторОбучение')}}</slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='admin'">{{$t('Администратор')}}</slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='bitrix'">{{$t('Менеджер')}}</slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='client'">{{$t('Клиент')}}</slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='marketing'">{{$t('РедакторМаркетинг')}}</slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='sales'">{{$t('РедакторПродажи')}}</slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='tech'">{{$t('РедакторТех')}}</slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='user'"><slot v-if="dialogList.users[`${chat.dialog_id}`].subrole==='leader'">{{$t('Агентство')}}</slot><slot v-else>{{$t('Агент')}}</slot></slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='agency'">{{$t('Агентство')}}</slot>
                    <slot v-else-if="dialogList.users[`${chat.dialog_id}`].user_role==='partner'||dialogList.users[`${chat.dialog_id}`].user_role==='partners'">{{$t('Партнер')}}</slot>
                    <slot v-else>{{dialogList.users[`${chat.dialog_id}`].user_role}}</slot>)</span>
                </div>
                <div class="list-chat-el__text_title" v-else>
                  {{$t('Удалён')}}
                </div>
                <div class="list-chat-el__text_description" v-if="dialogList.messages[`${chat.dialog_id}`]">{{ dialogList.messages[`${chat.dialog_id}`].text }}</div>
              </div>
              <div class="list-chat-el__unread" v-if="chat.count_unread">{{chat.count_unread}}</div>
              <div class="list-chat-el__time" v-if="dialogList.messages[`${chat.dialog_id}`]">{{dateFormat(dialogList.messages[`${chat.dialog_id}`].created_at) }}</div>
              <div class="options-dialog" @click.stop="showOptionsFunc(chat.dialog_id)">...</div>
              <div v-if="showOptions===chat.dialog_id" class="options-dialog-modal" @click.stop="hideDialog(chat.dialog_id)" v-on-clickaway="hideOptionsFunc">{{$t('Скрыть')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filterType===10&&!showChat" class="list-desktop-chat">
        <div class="chat-report-title">
          <div class="return-icon" @click="closeArchive" v-if="(role==='admin')||(role==='tp')">
            <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.435874 15.8121C-0.0984162 15.2717 -0.0984162 14.3987 0.435874 13.8568L13.1164 1.01899C13.8877 0.237158 15.1425 0.237158 15.9152 1.01899C16.6865 1.80083 16.6865 3.06974 15.9152 3.85157L5.06776 14.8352L15.9152 25.816C16.6865 26.5992 16.6865 27.8681 15.9152 28.65C15.1425 29.4318 13.8877 29.4318 13.1164 28.65L0.435874 15.8121Z" fill="var(--black-basic)"/>
            </svg>
          </div>
          <div>{{$t('Архив')}}</div>
        </div>
        <div class="chat-type-container" v-if="chatsArchive.dialogs">
          <div class="list-chats">
            <div class="list-chat-el" v-for="chat in chatsArchive.dialogs" @click="openChat(chat.dialog_id,chats.users[`${chat.dialog_id}`].username,chats.users[`${chat.dialog_id}`].user_role, chats.users[`${chat.dialog_id}`].subrole)">
              <div class="list-chat-el__icon">
                <img src="/my/noavatar.png" />
              </div>
              <div class="list-chat-el__text">
                <div class="list-chat-el__text_title" v-if="chatsArchive.users[`${chat.dialog_id}`]">
                  <slot v-if="chatsArchive.users[`${chat.dialog_id}`].username">{{chatsArchive.users[`${chat.dialog_id}`].username}}</slot>
                  <slot v-else>{{$t('Неизвестный')}}</slot>
                  <span v-if="chatsArchive.users[`${chat.dialog_id}`].user_role"><br/>
                    (<slot v-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='bank'">{{$t('РедакторБанк')}}</slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='tests'">{{$t('РедакторОбучение')}}</slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='admin'">{{$t('Администратор')}}</slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='bitrix'">{{$t('Менеджер')}}</slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='client'">{{$t('Клиент')}}</slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='marketing'">{{$t('РедакторМаркетинг')}}</slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='sales'">{{$t('РедакторПродажи')}}</slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='tech'">{{$t('РедакторТех')}}</slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='user'"><slot v-if="chatsArchive.users[`${chat.dialog_id}`].subrole==='leader'">{{$t('Агентство')}}</slot><slot v-else>{{$t('Агент')}}</slot></slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='agency'">{{$t('Агентство')}}</slot>
                    <slot v-else-if="chatsArchive.users[`${chat.dialog_id}`].user_role==='partner'||chatsArchive.users[`${chat.dialog_id}`].user_role==='partners'">{{$t('Партнер')}}</slot>
                    <slot v-else>{{chatsArchive.users[`${chat.dialog_id}`].user_role}}</slot>)</span>
                </div>
                <div class="list-chat-el__text_title" v-else>
                  {{$t('Удалён')}}
                </div>
                <div class="list-chat-el__text_description" v-if="chatsArchive.messages[`${chat.dialog_id}`]">{{ chatsArchive.messages[`${chat.dialog_id}`].text }}</div>
              </div>
              <div class="list-chat-el__unread" v-if="chat.count_unread">{{chat.count_unread}}</div>
              <div class="list-chat-el__time" v-if="chatsArchive.messages[`${chat.dialog_id}`]">{{dateFormat(chatsArchive.messages[`${chat.dialog_id}`].created_at) }}</div>
              <div class="options-dialog" @click.stop="showOptionsFunc(chat.dialog_id)">...</div>
              <div v-if="showOptions===chat.dialog_id" class="options-dialog-modal" @click.stop="showDialog(chat.dialog_id)" v-on-clickaway="hideOptionsFunc">{{$t('Вернуть')}}</div>
            </div>
          </div>
        </div>
        <div v-else class="empty-archive">{{$t('АрхивПуст')}}</div>
      </div>
      <div v-if="filterType!==0&&showChat">
        <div class="specialist-chat-wrapper"  @paste="(event) => {onpaste(event)}">
          <div class="header-specialist-chat">
            <div class="header-specialist-icons">
              <div class="return-icon" @click="closeChat" v-if="(role==='admin')||(role==='tp')">
                <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.435874 15.8121C-0.0984162 15.2717 -0.0984162 14.3987 0.435874 13.8568L13.1164 1.01899C13.8877 0.237158 15.1425 0.237158 15.9152 1.01899C16.6865 1.80083 16.6865 3.06974 15.9152 3.85157L5.06776 14.8352L15.9152 25.816C16.6865 26.5992 16.6865 27.8681 15.9152 28.65C15.1425 29.4318 13.8877 29.4318 13.1164 28.65L0.435874 15.8121Z" fill="var(--black-basic)"/>
                </svg>
              </div>
              <div class="avatar-icon" v-if="(role!=='admin')&&(role!=='tp')">
                <img :src="blackTheme?'/forQR-black.svg':'/forQR.svg'" />
              </div>
              <div class="avatar-icon" v-else>
                <a :href="'/profile/'+currentUserId">
                  <img src="/my/noavatar.png" />
                </a>
              </div>
            </div>
            <div class="header-specialist-description" v-if="(role!=='admin')&&(role!=='tp')">
              <div class="header-specialist-description__job">{{ $t('СпециалистТехПоддержки') }}</div>
              <div class="header-specialist-description__status">{{ $t('БылВСетиНедавно') }}</div>
            </div>
            <div class="header-specialist-description" v-else>
              <a :href="'/profile/'+currentUserId">
                <div class="header-specialist-description__job">{{currentName}} ({{currentUserRole?currentUserRole:''}})</div>
              </a>
            </div>
            <div @click="closeModal" class="close-btn" v-if="(role!=='admin')&&(role!=='tp')">X</div>
          </div>
          <div id="body-specialist-chat">
            <div id="scroll-chat" v-if="(role!=='admin')&&(role!=='tp')">
              <div v-for="mes in messages">
                <div class="me-question-wrapper" v-if="mes.sender_id==profile.id">
                  <div class="me-question">
<!--                    <div class="me-question__options">
                      <div class="me-question__option_delete" @click="deleteMessage(mes.message_id)">
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.96875 21.875C7.39583 21.875 6.90556 21.6712 6.49792 21.2635C6.08958 20.8552 5.88542 20.3646 5.88542 19.7917V6.25C5.59028 6.25 5.34271 6.15035 5.14271 5.95104C4.9434 5.75104 4.84375 5.50347 4.84375 5.20833C4.84375 4.91319 4.9434 4.66563 5.14271 4.46563C5.34271 4.26632 5.59028 4.16667 5.88542 4.16667H10.0521C10.0521 3.87153 10.1521 3.62396 10.3521 3.42396C10.5514 3.22465 10.7986 3.125 11.0938 3.125H15.2604C15.5556 3.125 15.8031 3.22465 16.0031 3.42396C16.2024 3.62396 16.3021 3.87153 16.3021 4.16667H20.4687C20.7639 4.16667 21.0111 4.26632 21.2104 4.46563C21.4104 4.66563 21.5104 4.91319 21.5104 5.20833C21.5104 5.50347 21.4104 5.75104 21.2104 5.95104C21.0111 6.15035 20.7639 6.25 20.4687 6.25V19.7917C20.4687 20.3646 20.2649 20.8552 19.8573 21.2635C19.449 21.6712 18.9583 21.875 18.3854 21.875H7.96875ZM7.96875 6.25V19.7917H18.3854V6.25H7.96875ZM10.0521 16.6667C10.0521 16.9618 10.1521 17.209 10.3521 17.4083C10.5514 17.6083 10.7986 17.7083 11.0938 17.7083C11.3889 17.7083 11.6365 17.6083 11.8365 17.4083C12.0358 17.209 12.1354 16.9618 12.1354 16.6667V9.375C12.1354 9.07986 12.0358 8.83229 11.8365 8.63229C11.6365 8.43299 11.3889 8.33333 11.0938 8.33333C10.7986 8.33333 10.5514 8.43299 10.3521 8.63229C10.1521 8.83229 10.0521 9.07986 10.0521 9.375V16.6667ZM14.2188 16.6667C14.2188 16.9618 14.3187 17.209 14.5188 17.4083C14.7181 17.6083 14.9653 17.7083 15.2604 17.7083C15.5556 17.7083 15.8031 17.6083 16.0031 17.4083C16.2024 17.209 16.3021 16.9618 16.3021 16.6667V9.375C16.3021 9.07986 16.2024 8.83229 16.0031 8.63229C15.8031 8.43299 15.5556 8.33333 15.2604 8.33333C14.9653 8.33333 14.7181 8.43299 14.5188 8.63229C14.3187 8.83229 14.2188 9.07986 14.2188 9.375V16.6667ZM7.96875 6.25V19.7917V6.25Z" fill="var(--blue-900-basic)"/>
                        </svg>
                      </div>
                      <div class="me-question__option_edit" @click="editMessage(mes.message_id, mes.text)">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 5H3C2.46957 5 1.96086 5.21071 1.58579 5.58579C1.21071 5.96086 1 6.46957 1 7V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H12C12.5304 18 13.0391 17.7893 13.4142 17.4142C13.7893 17.0391 14 16.5304 14 16V15" stroke="var(--blue-900-basic)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M13 3.00011L16 6.00011M17.385 4.58511C17.7788 4.19126 18.0001 3.65709 18.0001 3.10011C18.0001 2.54312 17.7788 2.00895 17.385 1.61511C16.9912 1.22126 16.457 1 15.9 1C15.343 1 14.8088 1.22126 14.415 1.61511L6 10.0001V13.0001H9L17.385 4.58511V4.58511Z" stroke="var(--blue-900-basic)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </div>-->
                    <div class="specialist-answer__text">
                      <div v-html="textWithUrltoHref(mes.text)"></div>
                      <div class="files-chat" v-if="mes.is_files">
                        <a :href="file" target="_blank" v-for="(file, index) in mes.files" :key="file">{{$t('Файл')}} {{index+1}}</a>
                      </div>
                      <div class="specialist-answer__time">{{ dateFormat(mes.created_at)}}</div>
                    </div>
                    <div class="specialist-answer__avatar">
                      <img src="/my/noavatar.png"/>
                    </div>
                  </div>
                </div>
                <div class="specialist-answer-wrapper" v-else>
                  <div class="specialist-answer">
                    <div class="specialist-answer__avatar">
                      <img :src="blackTheme?'/forQR-black.svg':'/forQR.svg'"/>
                    </div>
                    <div class="specialist-answer__text">
                      <div v-html="textWithUrltoHref(mes.text)"></div>
                      <div class="files-chat" v-if="mes.is_files">
                        <a :href="file" target="_blank" v-for="(file, index) in mes.files" :key="file">{{$t('Файл')}} {{index+1}}</a>
                      </div>
                      <div class="specialist-answer__time">{{dateFormat(mes.created_at)}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="scroll-chat" v-else>
              <div v-for="mes in messages">
                <div class="me-question-wrapper" v-if="mes.sender_id==profile.id">
                  <div class="me-question">
                    <div class="specialist-answer__text">
                      <div v-html="textWithUrltoHref(mes.text)"></div>
                      <div class="files-chat" v-if="mes.is_files">
                        <a :href="file" target="_blank" v-for="(file, index) in mes.files" :key="file">{{$t('Файл')}} {{index+1}}</a>
                      </div>
                      <div class="specialist-answer__time">{{ dateFormat(mes.created_at)}}</div>
                    </div>
                    <div class="specialist-answer__avatar">
                      <img :src="blackTheme?'/forQR-black.svg':'/forQR.svg'"/>
                    </div>
                  </div>
                </div>
                <div class="specialist-answer-wrapper" v-else>
                  <div class="specialist-answer">
                    <div class="specialist-answer__avatar">
                      <img src="/my/noavatar.png"/>
                    </div>
                    <div class="specialist-answer__text">
                      <div v-html="textWithUrltoHref(mes.text)"></div>
                      <div class="files-chat" v-if="mes.is_files">
                        <a :href="file" target="_blank" v-for="(file, index) in mes.files" :key="file">{{$t('Файл')}} {{index+1}}</a>
                      </div>
                      <div class="specialist-answer__time">{{dateFormat(mes.created_at)}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--          <div class="me-question-wrapper">
                        <div class="me-question">
                          <div class="specialist-answer__text">
                            Не работает функция сортировки
                          </div>
                          <div class="specialist-answer__avatar">
                            <img src="/my/noavatar.png"/>
                          </div>
                        </div>
                      </div>-->

          </div>
          <div class="specialist-chat-bottom" @drop="(event) => {dropFiles(event)}" @dragover="(event)=> {dragOver(event)}">
            <div class="date-input">
              <div class="specialist-chat-bottom__input">
                <div
                    ref="chatInpt"
                    class="input-text"
                    contentEditable="true"
                    @input="onInput"
                    @keydown.enter="sendMessage"
                    :placeholder="$t('НапишитеСообщение')"
                ></div>
<!--                <input class="input-text" type="text" placeholder="напишите сообщение" v-model="message" @keyup.enter="sendMessage"/>-->
                <div class="attachment">
                  <label  for="fileс">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24 11.7824L13.1261 22.0378C11.794 23.2942 9.98724 24 8.10332 24C6.2194 24 4.41265 23.2942 3.08051 22.0378C1.74838 20.7814 1 19.0774 1 17.3007C1 15.5239 1.74838 13.8199 3.08051 12.5635L13.9544 2.30812C14.8425 1.47055 16.047 1 17.3029 1C18.5589 1 19.7634 1.47055 20.6515 2.30812C21.5395 3.1457 22.0385 4.2817 22.0385 5.46621C22.0385 6.65073 21.5395 7.78673 20.6515 8.6243L9.76576 18.8797C9.32171 19.2985 8.71946 19.5338 8.09149 19.5338C7.46352 19.5338 6.86126 19.2985 6.41722 18.8797C5.97318 18.4609 5.72371 17.8929 5.72371 17.3007C5.72371 16.7084 5.97318 16.1404 6.41722 15.7216L16.4628 6.25852" stroke="#A5ACB9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </label>
                  <input  type="file" ref="fileс" v-on:change="handleFileUploadс()" class="form-control hidden" id="fileс">
                </div>
              </div>
              <div class="specialist-chat-bottom__input_send" @click="sendMessageButton">
                <div class="bottom-input-icon">
                  <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.655 1.53708C33.7978 1.1745 33.7198 0.762179 33.4546 0.476723C33.2545 0.261389 32.9746 0.14706 32.6894 0.153585C32.5857 0.155853 32.482 0.173983 32.3825 0.208204L1.56145 9.79905C1.15135 9.92667 0.866344 10.2991 0.85032 10.7283C0.834295 11.1575 1.09074 11.5501 1.49019 11.708L15.0124 17.0512L21.0092 29.0474C21.188 29.405 21.5604 29.624 21.9599 29.6063C22.3593 29.5886 22.711 29.3376 22.8574 28.9655L33.655 1.53708ZM15.5373 15.0807L4.90574 10.8797L28.5314 3.52788L15.5373 15.0807ZM16.9977 16.4926L21.805 26.1094L30.2178 4.73886L16.9977 16.4926Z" fill="var(--main-default-text)"/>
                  </svg>
                </div>
              </div>
            </div>
            <div class="files-chat">
              <a :href="file" target="_blank" v-for="(file, index) in files" :key="file">{{$t('Файл')}} {{index+1}}</a>
              <div class="loader-file-chat" v-if="loadFile">
                <div class="loader"></div>
                <div>{{$t('Файл')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import 'moment/locale/ru'
import {textWithUrltoHref} from "@/utils";

export default {
  name: "ChatReportDesk",
  props: ['unauth', 'forceShow'],
  data(){
    return{
      loadFile:false,
      showOptions:null,
      files:[],
      timer:null,
      areOptionsVisible:false,
      connection:null,
	    editMessageId:null,
      message:'',
      showChat:false,
      chats:[],
      chatsArchive:[],
      filterType:3,
      messages:[],
      currentDialog:0,
      currentName:null,
      currentUserRole:null,
      currentUserId:null,
      startChat:false,
    }
  },
  /*created() {
    console.log('Starting Connection to WebSocket Server')
    this.connection = new WebSocket("ws://echo.websocket.org/")

    this.connection.onopen = function (event){
      console.log(event)
      console.log("Successfully connected to the echo Websocket")
    }

    this.connection.onmessage=function (event){
      console.log(event)
    }
  },*/
  methods:{
    deleteMessage(id){
      // console.log(id)
    },
    editMessage(id, text){
      // console.log(text)
      this.editMessageId=id
      this.message=text
	    this.$refs.chatInpt.innerText=text
	    this.$nextTick(() => this.$refs.chatInpt.focus())
	    /*this.$refs.chatInpt.target.innerText=this.message*/
    },
    textWithUrltoHref,
    closeArchive(){
      this.filterType=3
      this.showOptions=null
    },
    openArchive(){
        axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/list?type=question&is_archived=true`, {
          headers: {
            user_id: this.profile.id
            /*username :`${encodeURIComponent(this.profile.director_name)}`*/
          },
        }).then((response)=>{
          this.chatsArchive=response.data.result
          this.filterType=10
        });
    },
    hideDialog(id){
      this.showOptions=null
      this.dialogList.dialogs=this.dialogList.dialogs.filter(el=>el.dialog_id!==id)
      this.$store.dispatch('SET_MESSAGES', this.dialogList)
      axios.post(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/setIsArchived`, {
        dialog_id: id,
        is_archived: true
      },{
        headers: {
          user_id: this.profile.id
        },
      })
    },
    showDialog(id){
      this.showOptions=null
      this.chatsArchive.dialogs=this.chatsArchive.dialogs.filter(el=>el.dialog_id!==id)
      if(this.chatsArchive.dialogs.length===0){
        this.chatsArchive.dialogs=null
      }
      axios.post(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/setIsArchived`, {
        dialog_id: id,
        is_archived: false
      },{
        headers: {
          user_id: this.profile.id
        },
      }).then(()=>{
        axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/list?type=question&is_archived=false`, {
          headers: {
            user_id: this.profile.id
          },
        }).then(response=>{
          this.$store.dispatch('SET_MESSAGES', response.data.result)
        });
      })
    },
    showOptionsFunc(id){
      if(id==this.showOptions){
        this.showOptions=null
      }else {
        this.showOptions=id
      }
    },
    hideOptionsFunc(id){
      this.showOptions=null
    },
    onInput(e){
      this.message=e.target.innerText
    },
    handleFileUploadс () {
      var input = 'fileс'
      var file = this.$refs[input].files[0]
      var self = this
      var reader = new FileReader()
      reader.fileName = file.name
      reader.onload = function (e) {
        self.loadFile=true
        $.post('https://cdn.itrielt.ru/upload/file', {
          file: {
            name: e.target.fileName,
            data: e.target.result
          }
        }).then(data => {
          if (data.status === 'success') {
            self.loadFile=false
            self.files.push(data.url)
            self.$forceUpdate()
          }
        })
      }
      reader.readAsDataURL(file)
    },
    getFileFromPasteEvent (event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items
      for (let index in items) {
        const item = items[index]
        if (item.kind === 'file') {
          return item.getAsFile()
        }
      }
    },
    uploadImage (file) {
      var self = this
      var reader = new FileReader()
      reader.fileName = file.name
      reader.onload = function (e) {
        $.post('https://cdn.itrielt.ru/upload/file', {
          file: {
            name: e.target.fileName,
            data: e.target.result
          }
        }).then(data => {
          if (data.status === 'success') {
            self.files.push(data.url)
            self.$forceUpdate()
          }
        })
      }
      reader.readAsDataURL(file)
    },
    dropFiles (ev) {
      ev.preventDefault()
      if (ev.dataTransfer.items) {
        [...ev.dataTransfer.items].forEach((item, i) => {
          if (item.kind === 'file') {
            const file = item.getAsFile()
            this.uploadImage(file)
          }
        })
      } else {
        [...ev.dataTransfer.files].forEach((file, i) => {
          this.uploadImage(file)
        })
      }
    },
    dragOver (ev) {
      ev.preventDefault()
    },
    onpaste (event) {
      if (this.openModal === null) return
      const file = this.getFileFromPasteEvent(event)
      if (!file) { return }
      this.uploadImage(file)
    },
    closeModal(){
      this.showOptions=null
      this.areOptionsVisible=false;
      this.messages=[];
      this.currentDialog=0;
      if(JSON.parse(this.visibleChat)) {
        this.$store.dispatch('SET_VISIBLE_CHAT', false)
      }
    },
    dateFormat (value) {
      let str=value.slice(8,10)+'.'+value.slice(5,7)+'.'+value.slice(0,4)+' '+value.slice(11,13)+':'+value.slice(14,16)
      return str
    },
    closeChat(){
      this.files=[]
      this.showChat=false;
      this.message='';
      this.messages=[];
      this.currentDialog=0;
     if(this.filterType===10){
        axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/list?type=question&is_archived=true`, {
          headers: {
            user_id: this.profile.id
          },
        }).then((response)=>{
          this.chatsArchive=response.data.result
        });
      }
    },
    sendMessage(event){
      if (event.keyCode === 13 && event.shiftKey === false) {
        event.stopPropagation();
        event.preventDefault();
        if(this.message||this.files.length){
          axios.post(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/messages/send`, {
            dialog_id: this.currentDialog,
            text: this.message,
            files: this.files
          }, {
            headers:{
              user_id: this.profile.id
            }
          }).then(()=>{
            this.message=''
            this.files=[]
            let input = 'chatInpt'
            this.$refs[input].innerText=''
            setTimeout(()=>{
              var msgboard = document.getElementById('scroll-chat');
              msgboard.scrollTop = msgboard.scrollHeight;
            },1)
          })
        }
      }
    },
    sendMessageButton(){
        if(this.message||this.files.length){
          axios.post(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/messages/send`, {
            dialog_id: this.currentDialog,
            text: this.message,
            files: this.files
          }, {
            headers:{
              user_id: this.profile.id
            }
          }).then(()=>{
            this.message=''
            this.files=[]
            let input = 'chatInpt'
            this.$refs[input].innerText=''
            setTimeout(()=>{
              var msgboard = document.getElementById('scroll-chat');
              msgboard.scrollTop = msgboard.scrollHeight;
            },1)
          })
        }
    },
    createChat(idFilt){
      if(idFilt===3){
        axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/new?type=question`, {
          headers: {
            user_id: this.profile.id
          },
        }).then((response)=>{
          this.openChat(response.data.result)
        });
      }
    },
    openChat(id, name, userRole, subrole){
      if(!name){
        name=this.$i18n.t('Неизвестный')
      }
      this.showOptions=null
      this.currentDialog=id
      this.currentName=name
      switch (userRole){
        case 'admin':
          this.currentUserRole=this.$i18n.t('Администратор')
          break;
        case 'bank':
          this.currentUserRole=this.$i18n.t('РедакторБанк')
          break;
        case 'tests':
          this.currentUserRole=this.$i18n.t('РедакторОбучение')
          break;
        case 'bitrix':
          this.currentUserRole=this.$i18n.t('Менеджер')
          break;
        case 'client':
          this.currentUserRole=this.$i18n.t('Клиент')
          break;
        case 'marketing':
          this.currentUserRole=this.$i18n.t('РедакторМаркетинг')
          break;
        case 'sales':
          this.currentUserRole=this.$i18n.t('РедакторПродажи')
          break;
        case 'tech':
          this.currentUserRole=this.$i18n.t('РедакторТех')
          break;
        case 'user':
          this.currentUserRole=this.$i18n.t('Агент')
          if(subrole ==='leader') this.currentUserRole = this.$i18n.t('Агентство')
          break;
        case 'agency':
          this.currentUserRole=this.$i18n.t('Агентство')
          break;
        case 'partner':
          this.currentUserRole=this.$i18n.t('Партнер')
          break;
        case 'partners':
          this.currentUserRole=this.$i18n.t('Партнер')
          break;
      }
      this.showChat=true
      axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/messages/list?dialog_id=${id}`, {
        headers: {
          user_id: this.profile.id
        },
      }).then((response)=>{
        if(response.data.result.messages) this.messages=response.data.result.messages
        /*if(response.data.result) this.messages=response.data.result*/
        if((this.role=='admin')||(this.role=='tp'))this.currentUserId=response.data.result.user.user_id
        if(this.messages.length){
	        axios.post(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/messages/read`, {
		        dialog_id: this.currentDialog,
		        message_id: this.messages[this.messages.length-1].message_id,
	        },{
		        headers: {
			        user_id: this.profile.id
		        },
	        }).then(()=>{
		        axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/list?type=question&is_archived=false`, {
			        headers: {
				        user_id: this.profile.id
			        },
		        }).then(response=>{
			        this.$store.dispatch('SET_MESSAGES', response.data.result)
		        });
	        })
        }
        setTimeout(()=>{
          var msgboard = document.getElementById('scroll-chat');
          msgboard.scrollTop = msgboard.scrollHeight;
        },1)
      });
      /*this.connectWs()*/
    },
    openChats(idFilt){
      if(this.profile.id){
        this.filterType=idFilt
      }
    }
  },
  computed:{
    blackTheme(){
      return this.$store.getters.getBlackTheme
    },
    dialogList(){
      return this.$store.getters.getMessages
    },
    currentMessage(){
      return this.$store.getters.getMessage
    },
    modalBoard(){
     return  this.$store.getters.getVisibleModalBoard
    },
    uuid () {
      return this.$store.getters.getUid
    },
    profile () {
      return this.$store.getters.getProfile
    },
    role () {
      return this.$store.getters.getRole
    },
    visibleChat () {
      return this.$store.getters.getVisibleChat
    }
  },
  watch:{
    forceShow(val) {
      this.areOptionsVisible = val
    },
    currentMessage(newValue){
      if(this.showChat&&this.areOptionsVisible){
        this.messages.push(newValue)
        setTimeout(()=>{
          var msgboard = document.getElementById('scroll-chat');
          msgboard.scrollTop = msgboard.scrollHeight;
        },1)
        axios.post(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/messages/read`, {
          dialog_id: this.currentDialog,
          message_id: newValue.message_id,
        },{
          headers: {
            user_id: this.profile.id
          },
        }).then(()=>{
          axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/list?type=question&is_archived=false`, {
            headers: {
              user_id: this.profile.id
            },
          }).then(response=>{
            this.$store.dispatch('SET_MESSAGES', response.data.result)
          })
        })
      }
    },
    dialogList(newValue){
      if(window.innerWidth>1090){
        if(newValue&&this.profile.id&&!this.startChat){
          this.startChat=true
          if(JSON.parse(this.visibleChat)) this.areOptionsVisible=true
        }
      }
    },
    /*profile(newValue){
      if(newValue.id){
        this.areOptionsVisible=true
      }
    },*/
    areOptionsVisible(newValue){
      if(newValue){
        if(this.profile.id){
          if((this.profile.role=='admin')||(this.profile.role=='tp')){
            this.openChats(3)
          } else {
            this.showChat=true
            if(this.dialogList.dialogs){
              this.openChat(this.dialogList.dialogs[0].dialog_id)
            } else {
              this.createChat(3)
            }

          }
        }
      }
    }
  },
  updated() {
    if (this.showChat&&this.areOptionsVisible){
      setTimeout(()=>{
        var msgboard = document.getElementById('scroll-chat');
        msgboard.scrollTop = msgboard.scrollHeight;
      },1)
    }
  },
  mounted() {

  },
}
</script>

<style lang="scss">
.loader-file-chat{
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: flex-start;
  .loader {
    color:var(--blue-900-basic);
    --n: 10;
    --s: 10px;
    --g: 2px;
    width: calc(var(--n)*(var(--s) + var(--g)) - var(--g));
    height: 21px;
    padding: var(--g);
    margin: 5px auto;
    border: 1px solid;
    border-radius: 5px;
    background: repeating-linear-gradient(90deg, currentColor 0 var(--s), var(--black-basic) 0 calc(var(--s) + var(--g)) ) left / calc((var(--n) + 1)*(var(--s) + var(--g))) 100% no-repeat content-box;
    animation: load 1.5s infinite;
  }
}
@keyframes load {
  0% {background-size: 0% 100%}
}
.chat-desktop{
  position: fixed;
  right: 0;
  bottom: 10px;
  top: unset !important;
  /*z-index: 1100;*/
  z-index: 50;
  .img-report-wrapper{
    cursor: pointer;
    background-color: var(--white-basic);
    padding: 30px 20px 30px 20px;
    border-radius: 20px 0 0 20px;
    position: relative;
    .counter-messages{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      background: #F52C2C;
      border-radius: 5.27778px;
      padding: 2px 6px;


      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */


      color: var(--main-default-text);
    }
    .img-report{
      width: 27px;
      height: 27px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .modal-report-chat{
    background: var(--white-basic);
    box-shadow: 3px 3px 40px rgba(40, 79, 154, 0.2);
    border-radius: 30px;
    width: 396px;
    height: 475px;
    position: fixed;
    right: 36px;
    bottom: 31px;
    overflow: auto;
    .empty-archive{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      /* identical to box height */


      color: var(--black-basic);
    }
    .list-desktop-chat{

      .chat-report-title{

        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 16px;
        /* identical to box height, or 82% */
        letter-spacing: 0.3px;
        color: var(--black-basic);

        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 17px;
        margin-bottom: 23px;
        position: relative;
        .return-icon{
          position: absolute;
          left: 50px;
          width: 12px;
          height: 21px;
          cursor: pointer;
          svg{
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .close-btn{
          cursor: pointer;
          position: absolute;
          right: 20px;
          font-size: 16px;
          line-height: 0;
        }
      }
      .chat-type-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 32px;
        .list-chats{
          display: flex;
          flex-direction: column;
          gap: 15px;
          .list-chat-el{
            position: relative;
            display: flex;
            align-items: flex-start;
            gap: 13px;
            padding-bottom:14px ;
            border-bottom: 1px solid #C4C4C4;
            cursor: pointer;
            .list-chat-el__icon{
              width: 45px;
              height: 45px;
              min-width: 45px;
              min-height: 45px;
              max-width: 45px;
              max-height: 45px;
              svg{
                min-width: 100%;
                min-height: 100%;
                max-width: 100%;
                max-height: 100%;
              }
              img{
                border-radius: 100px;
                min-width: 100%;
                min-height: 100%;
                max-width: 100%;
                max-height: 100%;
              }
            }
            .list-chat-el__text{
              max-width: 200px;
              width: 200px;
              .list-chat-el__text_title{

                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                /* identical to box height */


                color: var(--black-basic);
              }
              .list-chat-el__text_description{

                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                /* identical to box height */


                color:#93989a;
                overflow-x: hidden;
              }
            }
            .list-chat-el__time{
              position: absolute;
              right: 5px;
              top: 5px;

              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 14px;

              color: #9097A0;
            }
            .options-dialog{
              position: absolute;
              right: -15px;
              top: 1px;

              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 14px;

              color: #9097A0;
              -webkit-user-select: none;
              -khtml-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }
            .options-dialog-modal{
              position: absolute;
              z-index: 2;
              right: 5px;
              top: 1px;

              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 14px;

              color: #9097A0;
              padding: 10px;
              border: 1px solid gray;
              background-color: var(--white-basic);
              border-radius: 10px;
            }
            .list-chat-el__unread{
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: 15px;
              top: 25px;
              padding: 7px;
              border-radius: 18px;
              background: var(--blue-900-basic);
              font-style: normal;
              font-size: 11px;
              font-weight: 500;
              color: var(--main-default-text);
              height: 18px;
              line-height: 19px;
              min-width: 6px;
            }
          }
        }
      }
    }
    .specialist-chat-wrapper{
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 45.5608px;
      .header-specialist-chat{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 21px;
        padding: 24px 40px 0 23px;
        background-color: var(--white-basic);
        position: relative;
        .close-btn{
          color: var(--black-basic);
          cursor: pointer;
          position: absolute;
          right: 20px;
          top: 40px;
          font-size: 20px;
          line-height: 0;
        }
        .header-specialist-icons{
          display: flex;
          align-items: center;
          gap: 27px;
          .return-icon{
            width: 12px;
            height: 21px;
            cursor: pointer;
            svg{
              min-width: 100%;
              min-height: 100%;
              max-width: 100%;
              max-height: 100%;
            }
          }
          .avatar-icon{
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
            img{
              min-width: 100%;
              min-height: 100%;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
        .header-specialist-description{
          display: flex;
          flex-direction: column;
          .header-specialist-description__job{

            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            /* identical to box height */


            color: var(--black-basic);
          }
          .header-specialist-description__status{

            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            /* identical to box height */


            color: var(--gray-500-basic-default-color);
          }
        }
      }
      #body-specialist-chat{
        flex-grow: 1;
        position: relative;
        #scroll-chat{
          bottom: 0;
          left: 0;
          overflow: auto;
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          flex-direction: column;
          gap: 25px;
          padding: 0 23px 0;
          .me-question-wrapper{
            display: flex;
            justify-content: flex-end;
            padding-right: 0;
            .me-question{
              cursor: pointer;
              position: relative;
              display: flex;
              justify-content: flex-end;
              gap: 15px;
              width: 100%;
              .me-question__options{
                position: absolute;
                display: none;
                left: 0;
                bottom: 0;
                gap: 5px;
                .me-question__option_delete{
                  min-height: 14px;
                  max-height: 14px;
                  min-width: 14px;
                  max-width: 14px;
                  svg{
                    display: block;
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
                .me-question__option_edit{
                  min-height: 12px;
                  max-height: 12px;
                  min-width: 12px;
                  max-width: 12px;
                  svg{
                    display: block;
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
              .specialist-answer__avatar{
                min-width: 35px;
                min-height: 35px;
                max-width: 35px;
                max-height: 35px;
                img{
                  min-width: 100%;
                  min-height: 100%;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              .specialist-answer__text{
                max-width: 240px;
                word-wrap: break-word;
                width: 100%;
                padding: 7px 7px 24px 14px;

                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;

                color: var(--color-22215B);
                background: var(--back-E6EDFD);
                border-radius: 20px;
                position: relative;
                .files-chat{
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                }
                .specialist-answer__time{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 13px;

                  color: #9097A0;

                  position: absolute;
                  left: 14px;
                  bottom: 5px;
                }
              }
            }
            .me-question:hover{
              .me-question__options{
                display: flex;
              }
            }
          }
          .specialist-answer-wrapper{
            display: flex;
            justify-content: flex-start;
            padding-right: 0;
            .specialist-answer{
              position: relative;
              display: flex;
              gap: 15px;
              width: 100%;
              .specialist-answer__avatar{
                min-width: 35px;
                min-height: 35px;
                max-width: 35px;
                max-height: 35px;
                img{
                  min-width: 100%;
                  min-height: 100%;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              .specialist-answer__text{
                word-wrap: break-word;
                max-width: 240px;
                width: 100%;
                padding: 7px 7px 24px 14px;

                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;

                color: var(--color-22215B);
                border-radius: 20px;
                background: var(--chat-input-back);
                position: relative;
                .files-chat{
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                }
                .specialist-answer__time{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 13px;

                  color: #9097A0;

                  position: absolute;
                  left: 14px;
                  bottom: 5px;
                }
              }
            }
          }
        }
      }
      .specialist-chat-bottom{
        padding: 0 16px 19px;
        .date-input{
          width: 100%;
          display: flex;
          gap: 8px;
          background-color: var(--white-basic);
          align-items: flex-end;
          .specialist-chat-bottom__input{
            width: calc(100% - 70px);
            position: relative;
            .attachment{
              cursor: pointer;
              position: absolute;
              bottom: 13px;
              left: 5px;
              min-width: 20px;
              min-height: 20px;
              max-width: 20px;
              max-height: 20px;
              label{
                cursor: pointer;
              }
              svg{
                min-width: 100%;
                min-height: 100%;
                max-width: 100%;
                max-height: 100%;
              }
            }
            .input-text{
              width: 100%;
              padding: 10px 20px 10px 35px;
              min-height: 37px;
              max-height: 140px;
              overflow: auto;
              background: var(--chat-input-back);
              border-radius: 14.7674px;
              border: 0;
              outline: none;

              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              /* identical to box height */


              color: var(--black-basic);
            }
            .input-text[contentEditable=true]:empty:before {
              content: attr(placeholder);
              opacity: 0.6;
            }
          }
          .specialist-chat-bottom__input_send{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--blue-900-basic);
            border-radius: 14.7674px;
            min-width: 60.48px;
            height: 36.92px;
            .bottom-input-icon{
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 23.19px;
              min-height: 21.47px;
              max-width: 23.19px;
              max-height: 21.47px;
              svg{
                min-width: 100%;
                min-height: 100%;
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
        .files-chat{
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 1090px){
  .chat-desktop{
    display: none;
  }
}
</style>