/** FCM */
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import axios from "axios";

const firebaseConfig = {
    apiKey: "AIzaSyAW7--vrqOeFWUdCFuENyukNzbMa2AEdIs",
    authDomain: "test-9b2c1.firebaseapp.com",
    projectId: "test-9b2c1",
    storageBucket: "test-9b2c1.appspot.com",
    messagingSenderId: "783199662962",
    appId: "1:783199662962:web:e3c4070797a55c5208e3eb",
    measurementId: "G-3FV66XXVR2"
};

const VAPID_KEY = 'BOXQdIPEcBTo1FYuVtNEMqkJm5ZYQQHCjTDoTm5n2r4SnmUqw8yoPvGWFfujIRrrg6jvNkKJODqrGxtWk1GBtX0'
const LOCAL_STORAGE_TOKEN_KEY = 'firebase_token'

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp)

/** /FCM */

export async function grantSubscribe() {
    try {
        const permission = await Notification.requestPermission()

        if (permission === 'granted') {
            await subscribe()
            return 'ok'
        }

        if (permission === 'denied') {
            await unsubscribe()
            return 'ok'
        }
    } catch (err) {
        console.error(err)
        return err
    }
}

async function subscribe() {
    if (localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)) {
        return
    }

    try {
        const currentToken = await getToken(messaging, {vapidKey: VAPID_KEY})

        if (!currentToken) {
            console.log('No registration token available. Request permission to generate one.');
            return;
        }

        await axios.post('/cloud-messaging/subscribe', {
            token: currentToken
        })

        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, currentToken)

    } catch (err) {
        console.error(err);
    }
}

async function unsubscribe() {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)

    if (!token) {
        return
    }

    try {
        await axios.post('/cloud-messaging/unsubscribe', {
            token: token
        })

        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
    } catch (err) {
        console.error(err)
    }
}

export function getCurrentToken() {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
}